<template>
	<div id="app">
        <div style="display: flex; align-items:center; gap: 16px;">
		<h1 style="margin-bottom: 16px;">Citas</h1>
            <CSelect
            style="width:150px;"
            :options="displayPeriodOptions"
            v-model="displayPeriod"
        />
        </div>
		<calendar-view 
			:show-date="showDate"
            :items="appointments" 
            :display-period-uom="displayPeriod" 
            :display-period-count="1" 
            :starting-day-of-week="1"
			class="theme-default"
            @click-item="onClickEvent"
            >
			<calendar-view-header
				slot="header"
				slot-scope="t"
				:header-props="t.headerProps"
				@input="setShowDate">
            </calendar-view-header>
		</calendar-view>

<CModal title="Detalles de la Cita" color="primary" :show.sync="showModal"  class="modalReg">
        <div style=";margin: 0 auto !important;">
            <div style="display: flex; gap: 20px;">
            <p>Paciente:</p>
            <p>{{currentAppointment?.attendee?.name}}</p>
            </div>
            <div style="display: flex; gap: 20px;">
            <p>Fecha:</p>
            <p>{{currentAppointment?.date}}</p>
            </div>
            <div style="display: flex; gap: 20px;">
            <p>Hora:</p>
            <p>{{currentAppointment?.start | FormatTime}}</p>
            </div>
            
            <CButton @click="startVideocall" color="primary">
                Iniciar Videollamada
            </CButton>
        </div>
              <template #footer>
        <CButton @click="closeModal" color="secondary" size="lg">Cerrar</CButton>
      </template>
    </CModal>
	</div>
</template>
<script>
	import { CalendarView, CalendarViewHeader } from "vue-simple-calendar"
	require("vue-simple-calendar/static/css/default.css")
	require("vue-simple-calendar/static/css/holidays-us.css")
    import { getAttendingAppointments, getAppointmentInfo } from '../../api/agenda'
    import moment from 'moment'

    
	export default {
		name: 'app',
		data: function() {
			return {
        showDate: new Date(),
        displayPeriodOptions: [{ value: 'month', label: 'Mes' }, { value: 'week', label: 'Semana' }],
        displayPeriod: 'month',
        showModal: false,
        appointments: [],
        currentAppointment: {}
        }
		},
		components: {
			CalendarView,
			CalendarViewHeader,
		},
        methods: {
			setShowDate(d) {
				this.showDate = d;
			},
            onClickEvent(item) {
                this.getAppointmentData(item.id)
                this.showModal = true;
                this.currentItem = item;
            },
            getAppointements() {
                const data = {
                    date_start: moment(this.showDate).subtract(1, 'month').format("YYYY-MM-DD"),
                    date_end: moment(this.showDate).add(1, 'month').format("YYYY-MM-DD"),
                    specialty_id: 1,
                }
                getAttendingAppointments(data).then(res => {
                    this.appointments = res.appointments.map( appointment => {
                        return {
                            id: appointment.id,
                            startDate: new Date(appointment.start),
                            endDate: new Date(appointment.end),
                            title: `${appointment.attendee.name} - ${moment(appointment.start).format('HH:mm')}`
                        }
                    })
                })
            },
            getAppointmentData(appointment_id) {
                getAppointmentInfo({ appointment_id }).then(res => {
                    this.currentAppointment = res.appointment
                })
            },
            closeModal() {
                this.showModal = false;
            },
		startVideocall(){
            this.$store.commit("setVideoCallData", {
                status: true,
                alertID: null,
                geoUserAlert: null,
                fullView: true,
                deviceID: null,
                //////////////
                calledUserID: this.currentAppointment?.attendee_user_id,
                //////////////
                socketUrl: null,
                socketRoom: null,
                videocallID: null,
                hideVideo: false,
                silent: true
            })
		},
		},
        filters: {
            FormatTime: function (value) {
                return moment(value).format('HH:mm')
            }
        },
        watch: {
            showDate: function(val){
                this.getAppointements();
            }
        },
        mounted() {
            this.getAppointements();
        },
	}
</script>
<style>
	#app {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		color: #2c3e50;
		height: calc(100vh - 150px);
		width: 90vw;
		margin-left: auto;
		margin-right: auto;
	}
    .modalReg .modal-dialog {
        width: 20px;
        min-width: 500px;
    }
</style>