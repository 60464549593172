import {endpoints} from './endpoints'
import request from '../helpers/request'

export function getAttendingAppointments(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getAttendingAppointments,
      method: 'get',
      params: data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function getAppointmentInfo(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getAppointmentInfo,
      method: 'get',
      params: data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
